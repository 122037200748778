<template>
  <div class="search-results">
    <h1>
      Search results for <span style="color: #6290c8">{{ search }}...</span>
    </h1>
    <b-container fluid class="container">
      <b-row cols="1" cols-sm="1" cols-md="2" cols-lg="3">
        <!--  -->
        <b-col v-for="(blog, index) in resultPerPage" :key="blog.id">
          <b-card
            :header="blog.title"
            class="text-left"
            @mouseover="hoverCard(index)"
            @mouseout="hoverCard(-1)"
            @click="openBlog(blog)"
          >
            <b-card-text
              >{{ blog.description }}..<br />
              <div>
                <span
                  class="category"
                  @click.stop.prevent="openCategory(blog.category)"
                >
                  <a
                    ><small>{{ blog.category }}</small></a
                  >
                </span>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <BigPagin
      :activePage="activePage"
      v-bind:blogLength="results.length"
      v-bind:perPage="perPage"
      @pageChange="changePage"
    ></BigPagin>
  </div>
</template>

<script>
import { HTTP } from "../HTTP";
import BigPagin from "./helpers/BigPagin.vue";
import { ToBlog } from "./helpers/utils.js";

export default {
  name: "search",
  props: {
    SearchInput: String,
  },
  components: {
    BigPagin,
  },
  computed: {
    rows() {
      return this.results.length;
    },
    resultPerPage() {
      return this.results.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  data() {
    return {
      search: "",
      perPage: 16,
      currentPage: 1,
      results: [],
      selectedCard: -1,
      activePage: 1,
    };
  },
  async created() {
    const query = localStorage.getItem("search");
    this.search = query.toLowerCase();
    await HTTP.get("SearchBlog/en/" + query).then((response) => {
      if (response.data.data !== null) {
        this.results = response.data.data;
      }
    });
    await this.results.forEach((blog) => {
      blog.description = blog.description.slice(0, 60);
    });
  },
  methods: {
    openBlog(blog) {
      ToBlog(blog.id, blog.title);
    },
    hoverCard(selectedIndex) {
      this.selectedCard = selectedIndex;
    },
    isSelected(blogIndex) {
      return this.selectedCard === blogIndex;
    },
    openCategory(category) {
      localStorage.setItem("category", category);
      this.$router
        .push({
          name: "Category",
        })
        .catch(() => {});
    },
    changePage(currentPage) {
      this.currentPage = currentPage;
      this.activePage = currentPage;
    },
  },
};
</script>

<style scoped>
.search-results {
  max-width: 95%;
  margin: 0 auto;
  padding-top: 150px;
  padding-bottom: 30px;
}
.container {
  padding-bottom: 250px;
}

h1 {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-left: 5%;
  text-align: left;
}
.card {
  padding: 2.5%;
  text-align: left;
  margin: 2%;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px #376996;
  transition: height 0.5s, box-shadow 0.5s;
}
.card-header {
  font-weight: bold;
  background-color: #ffff;
  border-bottom: 1px solid #6290c8;
}
.card:hover {
  box-shadow: 1px 4px 8px 0px #376996;
}
.card:hover .category::before {
  width: 9em;
}

.pagination {
  padding-top: 40px;
}
.pagination > li.active > a {
  background-color: red;
}
</style>
