<template>
  <div>
    <div>
      <span
        class="dot"
        v-if="currentPage > 1"
        @click="setPage(currentPage - 1)"
      >
        <a style="color: #ffff">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="currentColor"
            class="bi bi-chevron-double-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
            <path
              fill-rule="evenodd"
              d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </a>
      </span>
      <span class="dot-dis" v-else>
        <a style="color: #ffff">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="currentColor"
            class="bi bi-chevron-double-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
            <path
              fill-rule="evenodd"
              d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </a>
      </span>
      <span
        v-for="pageNumber in totalPages"
        :key="pageNumber"
        class="dot"
        v-bind:class="{ active: pageNumber === activePage }"
        @click="setPage(pageNumber)"
        ><a style="color: #ffff">{{ pageNumber }}</a>
      </span>
      <span
        class="dot"
        v-if="currentPage < totalPages"
        @click="setPage(currentPage + 1)"
      >
        <a style="color: #ffff">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="currentColor"
            class="bi bi-chevron-double-right"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
            />
            <path
              fill-rule="evenodd"
              d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </a>
      </span>
      <span class="dot-dis" v-else>
        <a style="color: #ffff">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="currentColor"
            class="bi bi-chevron-double-right"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
            />
            <path
              fill-rule="evenodd"
              d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "BigPagin",
  props: {
    blogLength: Number,
    perPage: Number,
    activePage: Number,
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.blogLength / this.perPage);
    },
  },
  methods: {
    setPage(pageNumber) {
      this.currentPage = pageNumber;
      this.$emit("pageChange", this.currentPage);
    },
  },
  created() {},
};
</script>

<style scoped>
.dot {
  height: 1.65rem;
  width: 1.65rem;
  background-color: #6290c8;
  border-radius: 50%;
  display: inline-block;
  margin: 0.35%;
  vertical-align: middle;
  line-height: 1.65rem;
  text-align: center;
}
svg {
  vertical-align: baseline;
}
.dot-dis {
  height: 1.65rem;
  width: 1.65rem;
  background-color: #829cbc;
  border-radius: 50%;
  display: inline-block;
  margin: 0.35%;
  vertical-align: middle;
  line-height: 1.65rem;
  text-align: center;
}

.dot:hover {
  background-color: #376996;
}
.active {
  background-color: #1d3461;
}
</style>
